import React  from 'react';
import useSiteMetadata from '../hooks/useSiteMetadata';

const Share = ({location, title}) => {

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <div className="share">
      <div className="section-headline">share</div>
      <div className="share-buttons">
        <a className="icon icon-mail" href={`mailto:?${title}&amp;Body=I%20saw%20this%20and%20thought%20of%20you!%20${url}`}>
        </a>
    
        <a className="icon icon-facebook" href={`http://www.facebook.com/sharer.php?u=${title}`} target="_blank">
        </a>
        
        <a className="icon icon-twitter" href={`https://twitter.com/share?url=${url}&amp;text=${title};hashtags=simplesharebuttons`} target="_blank">
        </a>
      </div>
    </div>
  )
}

export default Share;
