import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Hero from '../components/hero'
import Share from '../components/share'

import '../styles/pages/blog-post.scss'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const { nextPage, prevPage } = get(this.props, 'pageContext')

    const { title: headline, publishDate: date, readTime, heroImage } = post;
    const heroData = { headline, date, readTime, heroImage }

    return (
      <Layout location={this.props.location}>
        <Helmet 
          title={`${post.title} | ${siteTitle}`} 
          bodyAttributes={{
            class: 'page-blog-post'
        }}/>
        <main id="MainContent">
          <Hero data={heroData}/>
          <div className="content" dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
          <Share title={headline} />
          <div className="pagination">
            {
              prevPage &&
              <Link className="previous" to={`/blog/${prevPage.slug}`}>
                <span className="pagination-title">previous</span>
                <span className="page-title">{prevPage.title}</span>
              </Link>
            }
            {
              nextPage &&
              <Link  className="next" to={`/blog/${nextPage.slug}`}>
                <span className="pagination-title">next</span>
                <span className="page-title">{nextPage.title}</span>
              </Link >
            }
          </div>
        </main>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      readTime
      heroImage{
        fluid(maxWidth: 1200, quality: 90, toFormat: WEBP, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
